import { ApiResponse, User } from "../interface";
import { Delete, Get, Post } from "../server";

export function ping<T = { status: string; csrf_token: string }>(): ApiResponse<T> {
  return Get<T>("/ping");
}

// auth
export function authUserWithToken<
  T = {
    access_token: string;
    session_key: string;
    user: User;
    csrf_token: string;
  },
>(accessToken: string): ApiResponse<T> {
  return Post<T>("/auth/sessions", {}, { access_token: accessToken });
}

// email login related
export function requestLoginWithEmail<
  T = { check_code?: string; email_status: string; sso_url?: string },
>(email: string, captcha_token: string): ApiResponse<T> {
  return Post<T>("/users/registration", { email }, {}, { "X-Turnstile-Token": captcha_token });
}

export function checkEmailLoginStatus<T = { validated: boolean; access_token: string }>(
  checkCode: string
): ApiResponse<T> {
  return Post<T>("/users/registration/check", { check_code: checkCode });
}

export function emailApproval<T>(checkCode: string, registerCode: string): ApiResponse<T> {
  return Post<T>("/users/registration/login", {
    check_code: checkCode,
    register_code: registerCode,
  });
}
export function resolveImpersonate<T>(requestId: string, resolveKey: string): ApiResponse<T> {
  const formData = new FormData();
  formData.append("request_id", requestId);
  formData.append("resolve_key", resolveKey);
  return Post<T>("/impersonate/resolve", formData);
}

export function register<T>(
  userName: string,
  checkCode: string,
  registerCode: string
): ApiResponse<T> {
  return Post<T>("/users/registration/register", {
    check_code: checkCode,
    register_code: registerCode,
    user_info: {
      display_name: userName,
    },
  });
}

// logout
export function logout<T>(): ApiResponse<T> {
  return Delete<T>("/auth", {});
}

const authApi = {
  ping,
  authUserWithToken,
  requestLoginWithEmail,
  checkEmailLoginStatus,
  emailApproval,
  resolveImpersonate,
  register,
  logout,
};

export default authApi;
